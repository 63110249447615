import React, { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ReactMarkdown from 'react-markdown';

import styles from './AccordionModule.css';
import { ArrowIcon } from '../../../atoms/Icons/Styleguide/ArrowIcon';
import { Media } from '../../../models/Strapi';
import { UrlService } from '../../../services/UrlService';

type AccordionItem = {
    title: string;
    details: string;
};

type Component = {
    id: number;
    accordions: AccordionItem[];
    color1: string;
    color2: string;
    textColor: string;
};

type AccordionProps = {
    title: string;
    titleColor?: string;
    component: Component[];
    background: Media;
    backgroundFallback: string;
};

const AccordionModule = React.memo((props: AccordionProps) => {
    const {
        title,
        titleColor,
        component: [accordionComponent],
    } = props;
    const [expanded, setExpanded] = useState({});
    const getBackground = ({ background, backgroundFallback }: AccordionProps) => {
        const result = {} as React.CSSProperties;

        if (background) {
            result.backgroundImage = `url(${UrlService.createStrapiMediaUrl(background.url)})`;
        }

        result.backgroundColor = backgroundFallback;
        return result;
    };
    const toggleExpanded = (itemId) => {
        setExpanded((prevExpanded) => ({
            ...prevExpanded,
            [itemId]: !prevExpanded[itemId],
        }));
    };

    return (
        <div className={styles.container} style={getBackground(props)}>
            <section className={styles.content}>
                <h2 style={{ color: titleColor }} className={styles.mainTitle}>
                    {title}
                </h2>
                {accordionComponent?.accordions?.map((item) => {
                    const isExpanded = !!expanded[item.title];

                    return (
                        <Accordion
                            key={item.title}
                            className={styles.item}
                            elevation={0}
                            disableGutters={true}
                            onClick={() => toggleExpanded(item.title)}
                            expanded={isExpanded}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <ArrowIcon
                                        className={styles.icon}
                                        color={accordionComponent?.textColor || '#F9F9F9'}
                                    />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={styles.itemTitle}
                                data-colorone={accordionComponent.color1}
                                data-colortwo={accordionComponent.color2}
                                style={{
                                    backgroundColor: isExpanded ? accordionComponent.color1 : accordionComponent.color2,
                                    color: accordionComponent?.textColor || '#F9F9F9',
                                }}
                            >
                                <Typography>{item.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                className={styles.itemDetails}
                                style={{
                                    color: accordionComponent?.textColor || '#F9F9F9',
                                }}
                            >
                                <Typography className={styles.itemDetailsContent}>
                                    <ReactMarkdown>{item.details}</ReactMarkdown>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </section>
        </div>
    );
});

export default AccordionModule;
